
import ProfileCard from "@/components/dashboard-area/ProfileCard.vue";
import { PopoutsModule } from "@/store/modules/popouts";

import { defineComponent } from "vue";
export default defineComponent({
  name: "FloatingProfileCard",
  components: { ProfileCard },
  props: {
    identity: {
      type: String,
      required: true,
    },
  },
  methods: {
    clickOutsideUserArea(event: any) {
      if (event.target.closest(".item.me")) return;
      if (event.target.closest(".context")) return;
      PopoutsModule.ClosePopout(this.identity);
    },
  },
});
